import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Make Up Day.`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`Our annual Free Thanksgiving Day workout will be at 9:00am on
Thursday.  Partner WOD so bring a friend!  The gym will only be open for
this class.`}</strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`*`}{`There will be no open gym on Friday but all open gym members are
welcome to come to our classes at no charge.  We will cancel the 6am &
6pm classes on Friday but the 10:30, 12:00, 4:00 & 5:00 classes are
available.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      